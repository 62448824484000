import React from 'react';
import Layout from '../components/layout'
import { Row, Col, Button } from "react-bootstrap";
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed'
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SEO from "../components/seo";

export default function Resume() {
    const filePath= "/images/uploads/cv_S_D_UYSAL_April2024_web.pdf"
    return (
        <Layout>
            <SEO title="Resume"></SEO>
            <Row className="mb-3">
                <Col md={10}>
                    <h1>Resume</h1>
                </Col>
                <Col md={2}>
                    <Button href={filePath} target="blank" variant="outline-primary"><FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon> Open</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                <ResponsiveEmbed aspectRatio="16by9">
                    <embed src={filePath} type="application/pdf" width="100%" height="900px" />
                </ResponsiveEmbed>
                </Col>
            </Row>
        </Layout>
    )
}
